<template>
  <div class="card border-info">
    <div class="card-header p-2 bg-info text-light">{{ bet.text }}</div>
    <div class="card-body p-0">
      <table class="table table-sm table-bordered mb-0 table-info">
        <thead>
          <tr class="alert-info">
            <th width="15%" class="text-center">
              <b-form-checkbox
                v-model="checkAll"
                :value="true"
                :unchecked-value="false"
              />
            </th>
            <th width="35%" class="text-center">เลข</th>
            <th width="35%" class="text-center">จ่าย</th>
            <th width="15%" class="text-center">ลบ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.number">
            <td class="text-center">
              <b-form-checkbox
                v-model="checkedNumbers"
                :value="item.number"
              />
            </td>
            <td class="text-center">
              <span class="text-primary">{{ item.number }}</span>
            </td>
            <td class="text-center">
              <span v-if="item.pay === 'c'" class="badge badge-danger">ปิดรับ</span>
              <span v-else-if="item.pay === 'h'" class="badge badge-warning">จ่ายครึ่ง</span>
              <span v-else class="text-success">{{ item.pay }}</span>
            </td>
            <td class="text-center">
              <button class="btn btn-sm btn-outline-danger py-0" @click="deleteNumbers([item.number])"><i class="fas fa-trash-alt"></i></button>
            </td>
          </tr>
          <tr v-if="!items.length">
            <td colspan="4" class="text-center small">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
      </table>

      <div class="p-2">
        <b-form-textarea
          v-model="inputNumbers"
          placeholder="เพิ่ม/แก้ไข เลข..."
          rows="2"
          max-rows="6"
          size="sm"
        ></b-form-textarea>

        <b-form-group class="my-2" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            v-model="selected"
            :options="options"
            :aria-describedby="ariaDescribedby"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-input
          v-if="selected==='s'"
          v-model="inputRate"
          v-on:keydown="keypress"
          class="mb-2"
          placeholder="ราคาจ่าย"
          size="sm"
          maxlength="4"
        ></b-form-input>

        <div class="pt-2 d-flex justify-content-between">
          <b-button variant="danger" size="sm" @click="deleteBulk"><i class="fas fa-trash-alt"></i> ลบ</b-button>
          <b-button variant="success" size="sm" @click="saveNumbers"><i class="far fa-save"></i> บันทึก</b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import cAlert from '@/helpers/alert'
import RoundService from '@/services/RoundService'
import _ from 'lodash'
import Swal from 'sweetalert2'

export default {
  name: 'NumberRate',
  props: ['bet', 'data'],
  data() {
    return {
      inputNumbers: '',
      inputRate: '',
      selected: null,
      options: [
        {text: 'ปิดรับ', value: 'c'},
        {text: 'จ่ายครึ่ง', value: 'h'},
        {text: 'ตั้งราคาจ่าย', value: 's'}
      ],
      checkedNumbers: [],
      checkAll: false
    }
  },
  computed: {
    items() {
      const objects = this.data?.setNumbers?.[this.bet.code] || {}

      const items = []
      for (const [key, val] of Object.entries(objects)) {
        items.push({
          number: key,
          pay: val
        })
      }

      return _.sortBy(items, (n)=>{
        return parseInt(n.number)
      })
    }
  },
  watch: {
    checkAll() {
      if(this.checkAll) {
        this.checkedNumbers = this.items.map((item)=>{
          return item.number
        })
      }else{
        this.checkedNumbers = []
      }
    },
    roundId() {
      this.inputNumbers = ''
      this.inputRate = ''
      this.selected = null
      this.checkedNumbers = []
      this.checkAll = false
    }
  },
  methods: {
    keypress(event) {
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', '.'].includes(event.key)) {
        event.preventDefault()
      }
    },
    saveNumbers() {

      const inputNumbers = this.inputNumbers
        .replace(/[^0-9]/g, ' ')
        .split(' ')
        .filter((n)=>{
          return {
            threeNumberTop: 3,
            threeNumberTode: 3,
            twoNumberTop: 2,
            twoNumberBottom: 2,
            runTop: 1,
            runBottom: 1
          }[this.bet.code] === n.length
        })

      const numbers = inputNumbers.concat(this.checkedNumbers)
        .reduce((ns, n) => {
          if (!ns.includes(n)) {
            ns.push(n)
          }
          return ns
        }, [])

      if(numbers.length === 0) {
        return cAlert({
          text: 'กรุณาใส่ตัวเลขให้ถูกต้อง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      if(!this.selected) {
        return cAlert({
          text: 'กรุณาเลือกการตั้งค่า',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }else
      if(this.selected==='s' && !this.inputRate) {
        return cAlert({
          text: 'กรุณาใส่ราคาจ่าย',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      let groupNumbers = numbers
      /**
       * 3 ตัวโต๊ด
       */
      if(this.bet.code === 'threeNumberTode') {
        const allNumbers = numbers.reduce((ns, n)=>{
          return ns.concat(this.gThreeNumberTode(n))
        }, [])

        groupNumbers = _.uniqBy(allNumbers)
      }

      const setNumbers = groupNumbers.reduce((ns, n)=>{
          if(['c', 'h'].includes(this.selected)) {
            ns[n] = this.selected
          }else
          if(this.selected==='s') {
            ns[n] = parseFloat(this.inputRate)
          }
          return ns
        }, {})

      RoundService.saveSettingNumbers(this.data._id, {
        code: this.bet.code,
        numbers: setNumbers
      })
      .then((response) => {
        if(response.success) {

          this.inputNumbers = ''
          this.inputRate = ''
          this.selected = null
          this.checkedNumbers = []
          this.checkAll = false

          this.$emit('reload')
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e) => {
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    deleteBulk() {
      this.deleteNumbers(this.checkedNumbers)
    },
    deleteNumbers(numbers) {
      if(numbers.length === 0) {
        return cAlert({
          title: 'Oops!',
          text: 'กรุณาเลือกตัวเลขที่ต้องการลบ',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      Swal.fire({
        title: 'ยืนยันลบ!',
        icon: 'info',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        if(res.isConfirmed) {

          let groupNumbers = numbers
          /**
           * 3 ตัวโต๊ด
           */
          if(this.bet.code === 'threeNumberTode') {
            const allNumbers = numbers.reduce((ns, n)=>{
              return ns.concat(this.gThreeNumberTode(n))
            }, [])

            groupNumbers = _.uniqBy(allNumbers)
          }

          RoundService.deleteSettingNumbers(this.data._id, {
            code: this.bet.code,
            numbers: groupNumbers
          })
          .then((response)=>{
            if(response.success) {
              this.inputNumbers = ''
              this.inputRate = ''
              this.selected = null
              this.checkedNumbers = []
              this.checkAll = false
              this.$emit('reload')
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'ลบเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            cAlert({
              ...e,
              title: 'ผิดพลาด!',
              text: e?.message || 'ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    },
    gThreeNumberTode(number) {
      if(!number)
        return []

      const n = number.slice('')
      const numbers = [
        `${n[0]}${n[1]}${n[2]}`,
        `${n[0]}${n[2]}${n[1]}`,
        `${n[1]}${n[0]}${n[2]}`,
        `${n[1]}${n[2]}${n[0]}`,
        `${n[2]}${n[1]}${n[0]}`,
        `${n[2]}${n[0]}${n[1]}`,
      ]

      return _.uniqBy(numbers)
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  .badge {
    font-weight: normal;
  }

  .table {
    background-color: #FFFFFF;
    border-left: 0;
    border-right: 0;

    tr {
      th:first-child, td:first-child {
        border-left: 0;
      }
      th:last-child, td:last-child {
        border-right: 0;
      }
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
